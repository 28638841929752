<template>
  <div v-if="_g_IsUserLogged" class="first-container">
    <div id="main-container" v-if="_g_UserFeatures.permissions.exercises">
      <LoadingForum v-if="isLoading" ref="loader" @loaded="finishLoading" :message="loadingMessage" />
      <div class="basic-iframe-container-main" v-if="is_basic_user && user_info_is_loaded" :class="{ 'loading-hidden': isLoading, 'loading-visible': !isLoading }">
        <div class="mdc-layout-grid__cell--span-12 cell-container">
          <div class="basic-iframe-container">
            <iframe name="basic-exercises" id="basic-exercises-id" style="overflow:hidden" width="100%" height="100%" frameBorder="0" @load="handleIframeLoad"></iframe>
          </div>
        </div>
      </div>
      <div v-else class="mdc-layout-grid__cell--span-12 advanced-exercises-container" :class="{ 'loading-hidden': isLoading, 'loading-visible': !isLoading }">
        <div class="iframe-container">
          <iframe name="exercises" id="exercises-id" style="overflow:hidden" width="100%" height="100%" frameBorder="0" @load="handleIframeLoad"></iframe>
        </div>
      </div>
    </div>
    <div v-else class="not-allowed-exercises-container">
      <p class="not-allowed-exercises-title"> SIN ACCESO </p>
      <p class="not-allowed-exercises"> Su plan actual no tiene acceso a esta herramienta. <a href="/app/store" class="link-to-store"> Pulse aquí para cambiar de plan. </a> </p>
    </div>
  </div>
  <div id="main-container" class="mdc-layout-grid__cell--span-12 mdc-layout-grid__cell--span-1-phone" v-else style="text-align: center;">
    <div class="container-image mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--align-middle">      
      <img src="https://s3.eu-west-1.amazonaws.com/assets.eurekers.com/anonymous/herramientas/main/ejercicios.jpg">
      <div class="text-center">
        <h2>Ejercicios interactivos</h2>
        <p>Practica la metodología Eurekers con ejercicios interactivos donde podrás ver diferentes casos de uso.</p>
      </div> 
      <div class="button-container">
        <button class="login-button" @click="_redirectToLogin()">LOGIN</button>
      </div> 
    </div>
  </div>
</template>

<script>
import APICaller from "@/components/codeComponents/APICaller.js";
import LoadingForum from '@/components/stockchart/components/LoadingForum.vue';
//import BasicExercises from '@/components/exercises/BasicExercises.vue';
//import AvancedExercises from '@/components/exercises/AvancedExercises.vue';

import {mapGetters } from "vuex";
export default {
  mixins: [APICaller],
  name: 'Exercises',
  components: {
    LoadingForum
  },
  data() {
    return {
      is_basic_user: false,
      user_info_is_loaded: false,
      basic_user_uid: "",
      isLoading: true,
      loadingMessage: "Cargando ejercicios..."
    };
  },
  
  created(){
    this._getUID();
    var userInfoInterval = setInterval(() => {
      if(this.basic_user_uid != ""){
        this._getUserInfo();
        clearInterval(userInfoInterval);
      }
    }, 250);
  },

  mounted() {
    this._setIframe();
    var isIframeLoaded = setInterval(() => {
      if(document.getElementById("basic-exercises-id") != null && document.getElementById("basic-exercises-id") != undefined){
        this._setBasicIframe();
        clearInterval(isIframeLoaded);
      }
    }, 250);
  },
  computed:{
    ...mapGetters(["_g_IsUserLogged", "_g_User", "_g_UserFeatures"]),
  },
  methods: {
    finishLoading() {
      setTimeout(() => {
        this.isLoading = false;
      }, 500);
    },
    
    handleIframeLoad() {
      // Check if we have a valid iframe with src attribute
      const iframe = this.is_basic_user ? 
        document.getElementById("basic-exercises-id") : 
        document.getElementById("exercises-id");
      
      if (iframe && iframe.src && iframe.src.trim() !== '') {
        if (this.$refs.loader) {
          this.$refs.loader.finishLoading();
        }
      }
    },

    _redirectToLogin() {
        window.location.href = "/user/login"; // Reemplaza la URL con la que desees redirigir
      },

    /* Basic user role */
    _getUID(){
      this.basic_user_uid = this._g_User.uid;
      this.user_info_is_loaded = true;
    },

    _getUserInfo(){
      this.is_basic_user = this._g_User.is_basic_user;
      this.user_info_is_loaded = true;
    },

    _setIframe(){
      var ExercisesFrame = document.getElementById("exercises-id");
      if (ExercisesFrame) {
        ExercisesFrame.src = process.env.VUE_APP_API + '/ejercicios-avanzados';
      }
    },

    _setBasicIframe(){
      var basicExercisesFrame = document.getElementById("basic-exercises-id");
      if (basicExercisesFrame) {
        basicExercisesFrame.src = process.env.VUE_APP_API + '/ejercicios';
      }
    },

  }
}
</script>



<style lang="scss" scoped>

  .first-container{
    height: 100%;
  }

  #main-container{
    height:100%;
    width:100%;
    overflow:hidden !important;
  }

  .iframe-container{
    height:100% !important;
  }

  .basic-iframe-container-main,
  .basic-iframe-container,
  .cell-container,
  .advanced-exercises-container{
    height: 100% !important;
  }
  
  header{
    display:none !important;
  }

  .container-image{
    /* Aplica un margen automático para centrar el div horizontalmente */
    margin: 0 auto;
    /* Ajusta el ancho máximo del contenedor */
    max-width: 800px;
    max-height: auto;
    
  }



  .container-image img{
    max-width: 500px;   
    max-height: 800px;
    margin: 20px;
    border-radius: 5px;
  }

  @media screen and (min-width: 768px) {
    .container-image img {
      width: 500px;
    }
  }

  .text-center {
    text-align: center;
    margin: 20px;
  }

  .container-image p {    
    text-align: justify;
  }

  .login-button{
    color: white;
    background-color: #00add8;
    border-radius: 20px;
    cursor: pointer;
    padding: 10px;
    text-decoration: none;
    border: none;
    outline: none;
    width: 150px;
    margin-top: 20px;
    font-size: 14px;
  }
  .login-button:hover{
    background-color: #00bdec;
  }

  .not-allowed-exercises-container{
    text-align: center;
    margin-top: 50px;
  }
  
  .link-to-store{
    color: #35add8 !important;
  }

  .not-allowed-exercises-title{
    color: #FF9B00;
    font-weight: bold;
  }

  /* Initially hide the main content but keep it rendered */
  .basic-iframe-container-main,
  .advanced-exercises-container {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  /* When loading finishes, fade it in */
  .loading-visible {
    opacity: 1;
  }

  /* Keep it hidden during loading */
  .loading-hidden {
    opacity: 0;
  }

</style>
